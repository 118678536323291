$max-container-width: rem-calc(2600);
$padding: 3vw;


%padded-container {
  width: 100%;
  max-width: $max-container-width;
  padding-left: $padding;
  padding-right: $padding;
  margin-left: auto;
  margin-right: auto;
}

.padded-container {
  @extend %padded-container;
}

.pad-mobile {
  @include breakpoint(small only) {
    padding-left: rem-calc(32);
    padding-right: rem-calc(32);
  }
}