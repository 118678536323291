$black: #000000;
$white: #ffffff;
$gray-1: #2c2c2c;
$gray-2: #939191;
$gray-3: #cccccc;
$gray-4: #f3efef;
$gray-5: #D8D8D8;
$gray-6: rgba(#A98080, 0.13);
$gray-7: rgba(#74576E, 0.2);

$colors: (
  "black": $black,
  "white": $white,
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
  "gray-4": $gray-4,
  "gray-5": $gray-5,
  "gray-6": $gray-6,
  "gray-7": $gray-7,
);

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }

  .c-#{$color} {
    color: $value;
  }
}


.c-gradient {
  // background: linear-gradient(to bottom, #56076F, #FF0000);
  background-color: #1a3d66;
  background-clip: text;
  line-height: 1.1 !important;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  // background: linear-gradient(to bottom, #56076F, #FF0000);
  background-color: #1a3d66;
}