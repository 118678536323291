//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Flexbox Utilities
//  20. Forms
//  21. Label
//  22. Media Object
//  23. Menu
//  24. Meter
//  25. Off-canvas
//  26. Orbit
//  27. Pagination
//  28. Progress Bar
//  29. Prototype Arrow
//  30. Prototype Border-Box
//  31. Prototype Border-None
//  32. Prototype Bordered
//  33. Prototype Display
//  34. Prototype Font-Styling
//  35. Prototype List-Style-Type
//  36. Prototype Overflow
//  37. Prototype Position
//  38. Prototype Rounded
//  39. Prototype Separator
//  40. Prototype Shadow
//  41. Prototype Sizing
//  42. Prototype Spacing
//  43. Prototype Text-Decoration
//  44. Prototype Text-Transformation
//  45. Prototype Text-Utilities
//  46. Responsive Embed
//  47. Reveal
//  48. Slider
//  49. Switch
//  50. Table
//  51. Tabs
//  52. Thumbnail
//  53. Title Bar
//  54. Tooltip
//  55. Top Bar
//  56. Xy Grid

@import "util/util";

// 1. Global
// ---------

$global-font-size: 1rem;
$global-width: rem-calc(1300);
$global-lineheight: 1.5;
$foundation-palette: (
  primary: $black,
  secondary: $black,
  success: $black,
  warning: $black,
  alert: $black,
);
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #000000;
$white: #fefefe;
$body-background: $white;
$body-font-color: $black;
$body-font-family: "Myriad Web Pro", Helvetica, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoints-hidpi: (
  hidpi-1: 1,
  hidpi-1-5: 1.5,
  hidpi-2: 2,
  retina: 2,
  hidpi-3: 3,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: "Garamond Premiere Pro", Times, Georgia, serif;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-styles: (
  small: (
    "h1": (
      "font-size": rem-calc(40),
      "line-height": 1,
      "font-weight": 500,
      "margin-bottom": rem-calc(8),
    ),
    "h2": (
      "font-size": rem-calc(33),
      "line-height": 1,
      "font-weight": 500,
      "margin-bottom": rem-calc(8),
    ),
    "h3": (
      "font-size": rem-calc(28),
      "line-height": 1,
      "font-weight": 500,
      "margin-bottom": rem-calc(8),
    ),
    "h4": (
      "font-size": rem-calc(23),
      "font-weight": 500,
      "line-height": 1.2,
      "margin-bottom": rem-calc(8),
    ),
    "h5": (
      "font-size": rem-calc(19),
      "font-weight": 500,
      "line-height": 1.2,
      "margin-bottom": rem-calc(8),
    ),
    "h6": (
      "font-size": rem-calc(16),
      "font-weight": 500,
      "margin-bottom": rem-calc(8),
    ),
    "p": (
      "font-size": rem-calc(16),
      "line-height": rem-calc(24),
      "letter-spacing": rem-calc(-0.1),
      "color": $gray-1,
      "font-weight": 200
    ),
    "small": (
      "font-size": rem-calc(12),
      "line-height": 1
    )
  ),
  medium: (
    "h1": (
      "font-size": rem-calc(43),
      "margin-bottom": rem-calc(16),
      "line-height": 1,
    ),
    "h2": (
      "font-size": rem-calc(34),
      "line-height": 1,
    ),
    "h3": (
      "font-size": rem-calc(28),
      "line-height": 1,
    ),
    "h4": (
      "font-size": rem-calc(22),
      "line-height": 1.2,
    ),
    "h5": (
      "font-size": rem-calc(18),
      "line-height": 1.2,
    ),
    "h6": (
      "font-size": rem-calc(16),
      "line-height": 1.2,
    ),
  ),
  large: (
    "h1": (
      "font-size": rem-calc(62),
      "line-height": 1,
      "letter-spacing": rem-calc(-2),
    ),
    "h2": (
      "font-size": rem-calc(50),
      "line-height": 1,
      "letter-spacing": rem-calc(-1.04)
    ),
    "h3": (
      "font-size": rem-calc(40),
      "line-height": 1,
      "letter-spacing": rem-calc(-0.83)
    ),
    "h4": (
      "font-size": rem-calc(32),
      "line-height": 1.125,
      "letter-spacing": rem-calc(-0.67)
    ),
    "h5": (
      "font-size": rem-calc(25),
      "line-height": 1.2,
      "letter-spacing": rem-calc(-0.52)
    ),
    "h6": (
      "font-size": rem-calc(20),
      "line-height": 1.2,
      "letter-spacing": rem-calc(-0.2)
    ),
    "p": (
      "font-size": rem-calc(18),
      "line-height": rem-calc(24),
      "letter-spacing": rem-calc(-0.1),
      "color": $gray-1,
      "font-weight": 200,
      "margin-bottom": rem-calc(24),
    ),
    "small": (
      "font-size": rem-calc(12),
      "line-height": 1.4
    ),
  ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 90%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$enable-code-inline: true;
$anchor-color: $primary-color;
$anchor-color-hover: lighten($black, 20%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$enable-cite-block: true;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;
$cite-color: $dark-gray;
$cite-font-size: rem-calc(13);
$cite-pseudo-content: "\2014 \0020";
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$code-block-padding: 1rem;
$code-block-margin-bottom: 1.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: get-color(alert);
$form-label-color-invalid: get-color(alert);
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background: $white;
$accordion-plusminus: true;
$accordion-plus-content: "\002B";
$accordion-minus-content: "\2013";
$accordion-title-font-size: rem-calc(12);
$accordion-item-color: $primary-color;
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: 1.25rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: 1px solid $light-gray;
$accordion-content-color: $body-font-color;
$accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-padding: $global-menu-padding;
$accordionmenu-nested-margin: $global-menu-nested-margin;
$accordionmenu-submenu-padding: $accordionmenu-padding;
$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;
$accordionmenu-item-background: null;
$accordionmenu-border: null;
$accordionmenu-submenu-toggle-background: null;
$accordion-submenu-toggle-border: $accordionmenu-border;
$accordionmenu-submenu-toggle-width: 40px;
$accordionmenu-submenu-toggle-height: $accordionmenu-submenu-toggle-width;
$accordionmenu-arrow-size: 6px;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: $white;
$badge-color-alt: $black;
$badge-palette: $foundation-palette;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: "/";
$breadcrumbs-item-separator-item-rtl: "\\";
$breadcrumbs-item-separator-color: $medium-gray;

// 11. Button
// ----------

$button-font-family: inherit;
$button-font-weight: null;
$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: $white;
$button-background: $black;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $global-radius;
$button-border: rem-calc(1) solid $gray-3;
$button-hollow-border-width: rem-calc(0);
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  large: 1.25rem,
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;
$button-responsive-expanded: true;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: ".button";
$buttongroup-expand-max: 6;
$buttongroup-radius-on-each: true;

// 13. Callout
// -----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-sizes: (
  small: 0.5rem,
  default: 1rem,
  large: 3rem,
);
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;

// 14. Card
// --------

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 1px solid $light-gray;
$card-shadow: none;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin-bottom: $global-margin;

// 15. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-z-index: 10;
$closebutton-default-size: medium;
$closebutton-offset-horizontal: (
  small: 0.66rem,
  medium: 1rem,
);
$closebutton-offset-vertical: (
  small: 0.33em,
  medium: 0.5rem,
);
$closebutton-size: (
  small: 1.5em,
  medium: 2em,
);
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 16. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-padding: $global-menu-padding;
$drilldown-nested-margin: 0;
$drilldown-background: $white;
$drilldown-submenu-padding: $drilldown-padding;
$drilldown-submenu-background: $white;
$drilldown-arrow-color: $primary-color;
$drilldown-arrow-size: 6px;

// 17. Dropdown
// ------------

$dropdown-padding: 1rem;
$dropdown-background: $body-background;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px,
);

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-arrow-padding: 1.5rem;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: null;
$dropdownmenu-submenu-background: $white;
$dropdownmenu-padding: $global-menu-padding;
$dropdownmenu-nested-margin: 0;
$dropdownmenu-submenu-padding: $dropdownmenu-padding;
$dropdownmenu-border: 1px solid $medium-gray;
$dropdown-menu-item-color-active: get-color(primary);
$dropdown-menu-item-background-active: transparent;

// 19. Flexbox Utilities
// ---------------------

$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

// 20. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: $black;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: $medium-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-padding: $form-spacing / 2;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

// 21. Label
// ---
